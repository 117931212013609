import {
    ApartmentOutlined,
    ClusterOutlined, ContainerOutlined,
    DatabaseOutlined,
    DollarOutlined, FolderOpenOutlined, FormOutlined,
    LogoutOutlined, MoneyCollectOutlined, MoneyCollectTwoTone,
    OrderedListOutlined,
    QuestionCircleOutlined, SettingOutlined, UsergroupAddOutlined
} from "@ant-design/icons";
import {useUserInfo} from "../../Helpers/user-session-helper";
import {create} from "zustand";
import {IconAirPlane, IconBank} from "../Icons";
import CashReport from "../CashReport";

export const useMenuInfo = create((set) => ({
    menuItems: [],
    updateMenu: (menu) => set((state) => state.menuItems = menu)
}))
function getItem(label, key, icon, children, type, permission = null) {
    const userInfo = useUserInfo.getState();
    if (permission){
        const {level} = permission;

        if (!userInfo.level || userInfo.level < level){
            return null;
        }
    }

    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

 export const updateMenu = () => {
    const setMenu = useMenuInfo.getState().updateMenu;
    setMenu([
         getItem('Quản trị', '/test', <SettingOutlined />, [
             getItem('Nhân sự', '/admin/personnel', <UsergroupAddOutlined />, null, null, {scope: 'master', level: 2048}),
             getItem('Phòng ban', '/admin/department', <ApartmentOutlined />, null, null, {scope: 'master', level: 2048}),
             getItem('Danh mục chi phí', '/admin/expense', <ClusterOutlined />, null, null, {scope: 'master', level: 2048}),
             getItem('Mùa vụ', '/admin/season', <FolderOpenOutlined />, null, null, {scope: 'master', level: 2048}),
             getItem('Tài khoản', '/admin/account', <DatabaseOutlined /> , null, null, {scope: 'master', level: 2048}),
             getItem('Tỉ giá', '/admin/exchange', <DollarOutlined />, null, null, {scope: 'master', level: 2048}),
             getItem('Hướng dẫn nhập', '/admin/notes', <QuestionCircleOutlined />, null, null, {scope: 'master', level: 2048}),

         ], null, {scope: 'master', level: 1024}),
        getItem('Danh sách đề nghị', '/request/1', <OrderedListOutlined />),
        //getItem('Báo cáo cửa hàng', '/cash_report', <MoneyCollectOutlined />),
        //getItem('(Adm) Báo cáo TM cửa hàng', '/cash_report_admin', <MoneyCollectTwoTone />, null, null, {scope: 'master', level: 1024}),
        getItem('Hồ sơ công tác phí', '/travel/', <IconAirPlane />, null, null, {scope: 'master', level: 4096}),
        getItem('Đề nghị cần xử lý', '/request/2', <FormOutlined />, null, null, {scope: 'master', level: 1024}),
        getItem('Danh sách chi tiền', '/admin/payment/list', <DollarOutlined />, null, null, {scope: 'master', level: 1024}),
        getItem('Mẫu thông tin tài khoản', '/bank_account', <IconBank />),

        {
            type: 'divider',
        },
        getItem('Thoát', 'logout', <LogoutOutlined />),
        getItem('Logs', '/admin/log', <ContainerOutlined />, null, null, {scope: 'master', level: 4096}),
     ]);
 }